import React from "react"
import styles from "../css/decouvrir.module.css"
import { useStaticQuery, graphql } from "gatsby"
import { FormattedMessage, Link } from "gatsby-plugin-intl"
import Img from "gatsby-image"

const Decouvrir = () => {
  const data = useStaticQuery(graphql`
    query {
      tradi: file(name: { eq: "vitrail-tradi" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      decors: file(name: { eq: "decors" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      envergures: file(name: { eq: "envergures" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <section id="decouvrir" className="first w-75-ns ph3 center">
      <h1 className="tc mt6 mb4">Qui sommes nous</h1>
      <div className="flex flex-wrap mb6">
        <Img
          className="w-100 w-50-l"
          fluid={data.tradi.childImageSharp.fluid}
          alt="Vitrail traditionnel"
        />
        <div className="w-50-l pl3-ns">
          <h3 className={styles.titre}>
            <FormattedMessage id="decouvrir.vitrail.title" />
          </h3>
          <p className={styles.contenu}>
            <FormattedMessage id="decouvrir.vitrail.p1" />
          </p>
          <p className={styles.contenu}>
            <FormattedMessage id="decouvrir.vitrail.p2" />
          </p>
          <Link className={styles.lien} to="/galerie-vitrail-traditionnel">
            <FormattedMessage id="decouvrir.vitrail.link" />
          </Link>
        </div>
      </div>

      <div className="flex flex-wrap-reverse mv6">
        <div className="w-50-l pr3-ns">
          <h3 className={styles.titre}>
            <FormattedMessage id="decouvrir.decors.title" />
          </h3>
          <p className={styles.contenu}>
            <FormattedMessage id="decouvrir.decors.p1" />
          </p>
          <p className={styles.contenu}>
            <FormattedMessage id="decouvrir.decors.p2" />
          </p>
          <p className={styles.contenu}>
            <FormattedMessage id="decouvrir.decors.p3" />
          </p>
          <p className={styles.contenu}>
            <FormattedMessage id="decouvrir.decors.p4" />
          </p>
          <p className={styles.contenu}>
            <FormattedMessage id="decouvrir.decors.p5" />
          </p>
          <Link className={styles.lien} to="/galerie-decors-fany-glass">
            <FormattedMessage id="decouvrir.decors.link" />
          </Link>
        </div>
        <div className="w-100 w-50-l">
          <Img fluid={data.decors.childImageSharp.fluid} alt="Décors" />
        </div>
      </div>

      <div className="flex flex-wrap mv6">
        <div className="w-100 w-50-l">
          <Img
            fluid={data.envergures.childImageSharp.fluid}
            alt="Projet d'envergures"
          />
        </div>
        <div className="w-50-l pl3-ns">
          <h3 className={styles.titre}>
            <FormattedMessage id="decouvrir.projet.title" />
          </h3>
          <p className={styles.contenu}>
            <FormattedMessage id="decouvrir.projet.p1" />
          </p>
          <p className={styles.contenu}>
            <FormattedMessage id="decouvrir.projet.p2" />
          </p>
          <p className={styles.contenu}>
            <FormattedMessage id="decouvrir.projet.p3" />
          </p>
          <p className={styles.contenu}>
            <FormattedMessage id="decouvrir.projet.p4" />
          </p>
          <p className={styles.contenu}>
            <FormattedMessage id="decouvrir.projet.p5" />
          </p>
          <p className={styles.contenu}>
            <FormattedMessage id="decouvrir.projet.p6" />
          </p>
          <Link className={styles.lien} to="/galerie-projets-d-envergures">
            <FormattedMessage id="decouvrir.projet.link" />
          </Link>
        </div>
      </div>
    </section>
  )
}

export default Decouvrir
