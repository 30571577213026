import React from "react"

import SEO from "../components/seo"
import Header from "../components/layout/js/header"
import Overlay from "../components/layout/js/overlay"
import Decouvrir from "../components/decouvrir/js/decouvrir"
import Contacter from "../components/layout/js/contacter"
import Footer from "../components/layout/js/footer"
import Copyrights from "../components/layout/js/copyrights"

const GaleriePage = () => (
  <>
    <SEO title="Qui sommes nous" />
    <Header />
    <Overlay />
    <Decouvrir />
    <Contacter />
    <Footer />
    <Copyrights />
  </>
)

export default GaleriePage
